import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`


:root{
    --maxWidth: 1280px;
    --black: #000000;
    --white: #fff;
    --lightGrey: #eee;
    --medGrey: #353535;
    --transparent: #FFFFFF00;
    --transparentGrey: #E8E8E89E;
    --darkGrey: #1c1c1c;
    --fontArchiBig:3.5rem;
    --fontMegaBig:3rem;
    --fontSuperBig: 2.5rem;
    --fontMedBig: 2rem;
    --fontBig: 1.5rem;
    --fontMed: 1.2rem;
    --fontSmall: 1rem;
    --fontXSmall: 0.8rem;
    --colorbg: #ffffff;
    --color1: #ffb400;
    --color2: #263238;
    --color3: #FFFFFFDE;
    --color4:rgb(108, 117, 125);
    --color5:#bdb51c;
* {
    box-sizing:border-box;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;


    #___gatsby {
        margin:0;
        padding:0;
        top:0;
        left:0;
        position: absolute;
        width:100%;
        height:100%;
    }
}
body{
        margin:0;
        padding:0;
    }


a,  link {
    cursor: pointer;
    text-decoration: none; 
    color: black;
}


.center-row{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

img.big{
    width: 100%;
}

img.left{
    width: 150px;
    margin: 10px;
    float: left;
}
img.right{
    width: 150px;
    margin: 10px;
    float: right;
}

.ytvideo{
    width:100%;
    height:300px;
    margin-top:2em;
}



}`;
