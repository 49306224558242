const Menu = {
    "data": [
        {
            "title":"Presentacion",
            "linkDir":"/"
        },
        {
            "title":"Contenidos",
            "linkDir":"/contenidos"
        },
        {
            "title":"Referencias",
            "linkDir":"/referencias"
        },
        {
            "title":"Creditos",
            "linkDir":"/creditos"
        },
        {
            "title":"Informacion para la Certificación",
            "linkDir":"/informacion"
        },
        {
            "title":"Certificación",
            "linkDir":"/certificacion"
        },
]
};

export default Menu;