import React, { useState } from 'react';
import styled from "styled-components";
import Container from './Container';
import Hamburguer from "@images/svg/hamburguer.svg";
import Menu from "@data/Menu.js"
import { Link } from 'gatsby';

const Wrapper = styled.nav`
    background-color: var(--color1);
    width:100%;
    height: 112px;
    padding: 15px;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
    h1{
        color: var(--white);
        font-size: 20px;
        font-weight: 600;
        margin-bottom:0;
    }
    h2{
        margin-top:0;
        color: var(--white);
        font-size: 16px;
        font-weight: 400;
    }
`;

const SubWrapper = styled.div`
    background-color: var(--white);
    width:100%;
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;

    ul{
        list-style: none;
        list-style-type: none;
    }
    

    nav{
        display: none;
    }

    .hamburguer{
        padding:2px;
        display: flex;
    }


    @media (min-width:768px){
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        nav{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            ul{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                margin: 5px 0;
            }
            li{
                display: flex;
                font-size:16px;
                line-height: 24px;
                font-weight: 400;
                margin: 0 16px;
            }

        }
        .hamburguer{
        padding:0px;
        display: none;
    }
    }
`;

const BigWrapper = styled.div`
    border-bottom: 1px solid #eee;
`;

const SideWrapper = styled.div`
    top: 0;
    position: fixed;
    display: ${props => props.open ? "flex" : "none"};
	flex-direction: row;
    
    width: 100%;
    height: 100%;
    .blackzone{
        height:100%;
        animation: abrirnav 0.5s;
        @keyframes abrirnav{
            0%{
                width:100%;
            }
            100%{
                width:30%;
            }
        }
        width:30%;
        background-color: #00000033;
    }
    .menu{

        h1{
            padding: 0 10px;
        }
        height:100%;
        animation: abrirmenu 0.5s;
        @keyframes abrirmenu{
            0%{
                width:0%;
                }
            100%{
                width:70%;
                }
        }
        width:70%;
        background-color: var(--white);
        text-decoration: none;

        ul, a, li {
        list-style: none;
        list-style-type: none;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        }
    

    }
    ;
`;

const Navbar = () => {

    const [open, setopen] = useState(false)

    return (
        <>
            <BigWrapper>
                <Container bg={"var(--color1)"}>
                    <Wrapper>
                        <h1>Conducta Responsable en Investigación</h1>
                        <h2>Curso de autoaprendizaje</h2>
                    </Wrapper>
                </Container>
                <Container bg={""}>
                    <SubWrapper>
                        <nav>
                            <ul>
                                {
                                 Menu["data"]&& Menu["data"].length>0
                                 ? Menu["data"].map((item,index) => (
                                    <li key={`navbaritem${index}`} ><Link to={item.linkDir?item.linkDir:"/"}>{item.title}</Link></li>
                                ))
                                :null
                                }
                            </ul>
                        </nav>
                        <div className={"hamburguer"} onClick={() => { setopen(prev => !prev) }}>
                            <img src={Hamburguer} alt={Hamburguer} />
                        </div>
                    </SubWrapper>
                </Container>
            </BigWrapper>
            <SideWrapper open={open} >
                <div className={"menu"}>
                    <h1>Menu</h1>
                    <ul>
                        {Menu["data"].map((subitem,subindex )=> (
                            <li key={`sidenavbar${subindex}`} ><Link to={subitem.linkDir}>{subitem.title}</Link></li>
                        ))}
                    </ul>
                </div>
                <div className={"blackzone"} onClick={() => { setopen(prev => !prev) }} />
            </SideWrapper>
        </>
    )
}

export default Navbar;
