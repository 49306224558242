import * as React from "react"
import styled from "styled-components";
import Navbar from "../components/styled/Navbar"
import Head from "@components/Head"
import Container from "../components/styled/Container"


const Simple = ({ children, title = "" }) => {
  return (
    <Wrapper>
      <Head title={title} />
      <Navbar />
      <Container>
        {children}
      </Container>
      <AdverCookies>
          Aviso: La página y el moodle utilizan cookies.
       </AdverCookies>
    </Wrapper>

  )
}

export default Simple

const Wrapper = styled.div`
height:100%;
width:100%;
padding-bottom:10em;
`;

const AdverCookies = styled.div`
position: fixed;
bottom: 0;
height:20px;
width:100%;
max-width:360px;
background-color: var(--color1);
color: white;
text-align:center;
font-size:12px;
`