import React  from 'react';
import styled from "styled-components";


const Content = styled.div`
width: 100%;
margin: auto;
@media (min-width: 768px){
max-width: 1100px;
}
`;

const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;

background-color: ${props=>props.bg?props.bg:""};
`;

const Container = ({children, bg=""}) => {
    return (<Wrapper bg={bg}>
        <Content>
            {children}
        </Content>
    </Wrapper>
    )
}

export default Container;